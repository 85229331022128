import { getApi } from "../helpers/clientApi"

const sendEmail = async ({ name, email, subject, message, token }) => {
  const url = `${process.env.URL_API_CHALUPA}/contactus`
  const params = {
    name,
    email,
    subject,
    message,
  }

  return getApi(url, params, token)
}

export default sendEmail
