import React from "react"

const Banner = () => {
  return (
    <section className="py-5">
      <div className="g-banner">
        <div className="g-banner-img" style={{ display: "block" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-11">
                <h2 className="title-banner-chpa" data-aos="fade-down">
                  Ocean shipping simplified!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
