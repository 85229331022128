import React, { useContext } from "react"
import PropTypes from "prop-types"

import DiscoverContext from "../../../context/discoverContext"

const Button = ({ number, title, description, cardClass, numberClass, data }) => {
  const { handleOpenModal } = useContext(DiscoverContext)

  return (
    <div className="col-lg-3 col-md-12 col-12 mb-4">
      <div
        className={`card card-content-chpa ${cardClass} shadow`}
        data-aos="zoom-in"
        style={{ height: "6.2rem" }}
        role="button"
        tabIndex={0}
        onClick={() => handleOpenModal(data)}
        onKeyDown={() => {}}
      >
        <div className={`col-3 number-chpa ${numberClass}`}>
          <h1>{number}</h1>
        </div>
        <div className="col-9 title-content-chpa">
          <h6 className="card-title-chpa">{title}</h6>
          {description && <p className="card-text-chpa">{description}</p>}
        </div>
      </div>
    </div>
  )
}

Button.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cardClass: PropTypes.string.isRequired,
  numberClass: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default Button
