import React, { useContext } from "react"
import { format, parse } from "date-fns"

// Contexts
import SearchContext from "../../../context/searchContext"

const SectionResult = () => {
  const { resultSearchModal } = useContext(SearchContext)
  const { data } = resultSearchModal
  const { dataSearch } = data
  const originLocation = dataSearch?.originLocation
  const destinationLocation = dataSearch?.destinationLocation
  const searchStartDate = dataSearch?.searchStartDate

  let searchDepartureDate = ""

  if (searchStartDate) {
    searchDepartureDate = format(parse(searchStartDate, "MM-dd-yyyy", new Date()), "MMMM dd, yyyy")
  }

  return (
    <div className="row">
      <div className="col-lg-4 col-md-12 col-12 results-position-one">
        <div className="results-position-two">
          <span>Origin Port</span>
          <div className="card card-results card-content-chpa mt-2">
            <div className="col-lg-3 col-md-3 col-3 flag-chpa">
              <span className={`flag-icon flag-icon-${originLocation?.abbreviation}`} style={{ fontSize: "30px" }}></span>
            </div>
            <div className="col-lg-9 col-md-9 col-9 results-content-chpa">
              <p>{originLocation?.string}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-12 col-12 results-position-one">
        <div className="results-position-two">
          <span>Destination Port</span>
          <div className="card card-results card-content-chpa mt-2">
            <div className="col-lg-3 col-md-3 col-3 flag-chpa">
              <span className={`flag-icon flag-icon-${destinationLocation?.abbreviation}`} style={{ fontSize: "30px" }}></span>
            </div>
            <div className="col-lg-9 col-md-9 col-9 results-content-chpa">
              <p>{destinationLocation?.string}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-12 col-12 results-position-one">
        <div className="results-position-two">
          <span>Search Departure Date</span>
          <div className="card card-results card-content-chpa mt-2">
            <div className="col-lg-12 col-md-12 col-12 results-content-chpa">
              <p>{searchDepartureDate}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionResult
