import { format } from "date-fns"

const extractSearchData = ({
  locationOne,
  cyCheckOne,
  locationTwo,
  cyCheckTwo,
  containerType,
  containerSize,
  containerQuantity,
  containerWeight,
  commodity,
  dangerous,
  searchStartDate,
  companyName,
  locationHelpOne,
  companyEmailAddress,
  imoNumber,
  locations,
}) => {

  return {
    originLocation: locationOne,
    originHaulage: cyCheckOne ? "CY" : "SD",
    destinationLocation: locationTwo,
    destinationHaulage: cyCheckTwo ? "CY" : "SD",
    containerType: containerType,
    containerSize: containerSize,
    containerQuantity: containerQuantity || 1,
    containerWeight: containerWeight || 0.1,
    soc: false,
    commodity: commodity,
    dangerousCargo: dangerous,
    searchStartDate: format(searchStartDate, "MM-dd-yyyy"),
    nameCompany: companyName,
    locationCompany: locationHelpOne,
    emailCompany: companyEmailAddress,
    IMO: imoNumber,
    locations,
  }
}

export { extractSearchData }
