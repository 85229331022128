import React, { useContext } from "react"

// Components
import Modal from "../../Modal"

// Context
import SearchContext from "../../../context/searchContext"

// Assets
import urlImg from "../../../images/LogoChalupaNo1.png"

const ResultNoSearch = () => {
  const { noResultSearchModal, handleCloseNoSearchModal } = useContext(SearchContext)
  const { isShow, isAdvancedSearch, message } = noResultSearchModal

  return (
    <Modal
      idModal="no-search-modal"
      ariaLabelledby="no-search-modal"
      isShow={isShow}
      onButtonClick={handleCloseNoSearchModal}
    >
      <div className="modal-content">
        <div className="modal-header-chpa">
          <h5 className="modal-title-chpa">SEARCH RESULTS</h5>
          <button type="button" className="close-chpa" onClick={() => handleCloseNoSearchModal("close")} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="no-results-position-chpa">
                  <img className="icon-no-results-chpa" src={urlImg} alt="Logo Chalupa" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="no-results-position-chpa mrg-no-results-chpa">
                  <h3 className="title-no-results-chpa">{message}</h3>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="no-results-position-chpa mrg-no-results-chpa">
                  <button type="button" className="btn button-chpa" onClick={() => handleCloseNoSearchModal("close")}>
                    Close!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isAdvancedSearch && (
          <div className="return-search-buttons-chpa col-12">
            <button type="button" className="btn btn-link scroll-btn" onClick={() => handleCloseNoSearchModal("close")}>
              {"< Return Advanced Search"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ResultNoSearch
