import React, { useContext } from "react"

// Component
import Modal from "../../Modal"

// Context
import ContactUsContext from "../../../context/contactUsContext"

const ModalContainer = () => {
  const { handleCloseModal, modal } = useContext(ContactUsContext)
  const { message, isSuccess, isShow } = modal

  return (
    <Modal idModal="contactUsModal" classModal="bd-modal-sent-email" ariaLabelledby="contactUs" isShow={isShow} onButtonClick={handleCloseModal}>
      <div className="modal-content">
        <div className="modal-header-chpa">
          <h5 className="modal-title-chpa">CONTACT US</h5>
          <button type="button" className="close-chpa" aria-label="Close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="contact-us-position-chpa" style={{ fontSize: "5rem" }}>
                  <i className={`fa ${isSuccess ? "fa-envelope" : "fa-times-circle"} icon-contact-us`}></i>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="contact-us-position-chpa mrg-contact-us-chpa">
                  <h3 className="title-contact-us-chpa">{message}</h3>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="contact-us-position-chpa mrg-contact-us-chpa">
                  <button type="button" className="btn button-chpa" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalContainer
