import React, { useState } from "react"

// Context
import ContactUsContext from "../../../context/contactUsContext"

// Components
import Form from "./Form"
import Modal from "./ModalContainer"

const ContactUs = () => {
  const [modal, setModal] = useState({
    isShow: false,
    isSuccess: false,
    message: "",
  })

  const handleCloseModal = (type) => {
    if (type === "close") {
      setModal((oldData) => ({
        ...oldData,
        isShow: false,
      }))
    }
  }

  return (
    <ContactUsContext.Provider value={{ modal, setModal, handleCloseModal }}>
      <section className="py-5" id="contactUs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="g-banner-plane">
                <div className="g-banner-img-plane" style={{ display: "block" }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 text-center">
              <h1 className="mb-4 title-chpa-contact-us">Contact Us</h1>
            </div>
            <div className="col-lg-6 col-md-12 col-12" data-aos="zoom-in">
              <Form />
            </div>
          </div>
        </div>

        <Modal />
      </section>
    </ContactUsContext.Provider>
  )
}

export default ContactUs
