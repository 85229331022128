import React, { useContext } from "react"

// Contexts
import SearchContext from "../../../context/searchContext"

// Components
import Modal from "../../Modal"
import SectionSearch from "./SectionSearch"
import SectionResult from "./SectionResult"
import SectionScrollTransitTime from "./SectionScrollTransitTime"

const ResultSearch = () => {
  const { resultSearchModal, setResultSearchModal, setShowAdvancedModal } = useContext(SearchContext)
  const { isShow, isAdvancedSearch } = resultSearchModal

  const handleCloseModal = (type) => {
    const { isAdvancedSearch: isAdvanced } = resultSearchModal

    if (type === "close") {
      setResultSearchModal((oldData) => {
        return {
          ...oldData,
          isShow: false,
        }
      })

      if (isAdvanced) {
        setTimeout(() => {
          setShowAdvancedModal(true)
        }, 400)
      }
    }
  }

  return (
    <Modal
      idModal="result-search-modal"
      classModal="bd-modal-search-results"
      classTypeModal="modal-xl"
      ariaLabelledby="result-search"
      isShow={isShow}
      onButtonClick={handleCloseModal}
    >
      <div className="modal-content">
        <div className="modal-header-chpa">
          <h5 className="modal-title-chpa">SEARCH RESULTS</h5>
          <button type="button" className="close-chpa" onClick={() => handleCloseModal("close")} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="container">
            <SectionSearch />
          </div>
          <div className="container">
            <div className="row">
              <SectionScrollTransitTime />
              <div className="col-lg-12 col-md-12 col-12">
                <SectionResult />
              </div>
              {isAdvancedSearch && (
                <div className="return-search-buttons-chpa col-12">
                  <button type="button" className="btn btn-link scroll-btn" onClick={() => handleCloseModal("close")}>
                    {"< Return Advanced Search"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ResultSearch
