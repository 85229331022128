import * as yup from "yup"
import Swal from "sweetalert2"
import $ from "jquery"

import { countries, commodities, messageError } from "../constants"

const existsSelect = (value) => {
  return value
}

const existsCountry = (value) => {
  return countries.indexOf(value) !== -1
}

const existsComodity = (value) => {
  if (value.length === 0) {
    return true
  }

  return commodities.indexOf(value) !== -1
}

const validateCheckBoxLocation = (cyCheckBox, sdCheckBox) => {
  return cyCheckBox || sdCheckBox
}

const schemaAdvanceSearch = yup.object().shape({
  locationOne: yup
    .string()
    .required(messageError.FORM_ADVANCE.ORIGIN_LOCATION_REQUIRED),

  cyCheckOne: yup
    .boolean()
    .test("checkBox", messageError.FORM_ADVANCE.ORIGIN_LOCATION_CHECKBOX, (value, context) =>
      validateCheckBoxLocation(value, context.parent.sdCheckOne)
    ),

  sdCheckOne: yup
    .boolean()
    .test("checkBox", messageError.FORM_ADVANCE.ORIGIN_LOCATION_CHECKBOX, (value, context) =>
      validateCheckBoxLocation(context.parent.cyCheckOne, value)
    ),

  locationTwo: yup
    .string()
    .required(messageError.FORM_ADVANCE.DESTINATION_LOCATION_EXISTS),
    
  cyCheckTwo: yup
    .boolean()
    .test("checkBox", messageError.FORM_ADVANCE.DESTINATION_LOCATION_CHECKBOX, (value, context) =>
      validateCheckBoxLocation(value, context.parent.sdCheckTwo)
    ),

  sdCheckTwo: yup
    .boolean()
    .test("checkBox", messageError.FORM_ADVANCE.DESTINATION_LOCATION_CHECKBOX, (value, context) =>
      validateCheckBoxLocation(context.parent.cyCheckTwo, value)
    ),

  containerType: yup
    .mixed()
    .test("comboBox", messageError.FORM_ADVANCE.CONTAINER_DETAILS_TYPE_REQUIRED, (value) => existsSelect(value)),

  containerSize: yup
    .mixed()
    .test("comboBox", messageError.FORM_ADVANCE.CONTAINER_DETAILS_SIZE_REQUIRED, (value) => existsSelect(value)),

  containerQuantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .integer(messageError.FORM_ADVANCE.CONTAINER_DETAILS_QUANTITY_NUMBER)
    .min(1, messageError.FORM_ADVANCE.CONTAINER_DETAILS_QUANTITY_MIN),

  containerWeight: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0.01, messageError.FORM_ADVANCE.CONTAINER_DETAILS_WEIGHT_MIN),

  commodity: yup.string().when("dangerous", {
    is: true,
    then: yup.string().required(messageError.FORM_ADVANCE.ORIGIN_LOCATION_REQUIRED),
  }).test("isExists", messageError.FORM_ADVANCE.COMMODITY_EXISTS, (value) => existsComodity(value)),

  dangerous: yup.boolean(),

  imoNumber: yup.number().when("dangerous", {
    is: true,
    then: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(messageError.FORM_ADVANCE.COMMODITY_IMO_REQUIRED)
      .integer(messageError.FORM_ADVANCE.COMMODITY_IMO_NUMBER)
      .min(1, messageError.FORM_ADVANCE.COMMODITY_IMO_MIN),
  }),

  searchStartDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required(messageError.FORM_ADVANCE.SEARCH_START_DATE_REQUIRED),

  companyName: yup.string().required(messageError.FORM_ADVANCE.COMPANY_DETAILS_NAME_REQUIRED),

  locationHelpOne: yup
    .string()
    .required(messageError.FORM_ADVANCE.DESTINATION_LOCATION_EXISTS),

  companyEmailAddress: yup
    .string()
    .required(messageError.FORM_ADVANCE.COMPANY_DETAILS_EMAIL_REQUIRED)
    .email(messageError.FORM_ADVANCE.COMPANY_DETAILS_EMAIL_REQUIRED),
})

const viewErrorAdvancedSearch = (errors) => {
  let messageTitle, messageError

  if (errors.locationOne || errors.cyCheckOne || errors.sdCheckOne) {
    messageTitle = "Incomplete field(s) at Origin Location"
    messageError = `<ul class="text-left">
        ${errors.locationOne ? "<li>" + errors.locationOne.message + "</li> " : ""}

        ${errors.cyCheckOne ? "<li>" + errors.cyCheckOne.message + "</li> " : ""}
      </ul>`

    $("#collapseOrigin").collapse("show")
  } else if (errors.locationTwo || errors.cyCheckTwo || errors.sdCheckTwo) {
    messageTitle = "Incomplete field(s) at Destination Location"
    messageError = `<ul class="text-left">
        ${errors.locationTwo ? "<li>" + errors.locationTwo.message + "</li> " : ""}

        ${errors.cyCheckTwo ? "<li>" + errors.cyCheckTwo.message + "</li> " : ""}
      </ul>`

    $("#collapseDestination").collapse("show")
  } else if (errors.containerType || errors.containerSize || errors.containerQuantity || errors.containerweight) {
    messageTitle = "Incomplete field(s) at Container Details"
    messageError = `<ul class="text-left">
        ${errors.containerType ? "<li>" + errors.containerType.message + "</li> " : ""}

        ${errors.containerSize ? "<li>" + errors.containerSize.message + "</li> " : ""}

        ${errors.containerQuantity ? "<li>" + errors.containerQuantity.message + "</li> " : ""}

        ${errors.containerweight ? "<li>" + errors.containerweight.message + "</li> " : ""}
      </ul>`

    $("#collapseContainerDetails").collapse("show")
  } else if (errors.commodity || errors.imoNumber) {
    messageTitle = "Incomplete field(s) at Commodity Details"
    messageError = `<ul class="text-left">
        ${errors.commodity ? "<li>" + errors.commodity.message + "</li> " : ""}

        ${errors.imoNumber ? "<li>" + errors.imoNumber.message + "</li> " : ""}
      </ul>`

    $("#collapseCommodityDetails").collapse("show")
  } else if (errors.searchStartDate) {
    messageTitle = "Incomplete field(s) at Rate Validity"
    messageError = `<ul class="text-left">
        ${errors.searchStartDate ? "<li>" + errors.searchStartDate.message + "</li> " : ""}
      </ul>`

    $("#collapseRateValidity").collapse("show")
  } else if (errors.companyName || errors.locationHelpOne || errors.companyEmailAddress) {
    messageTitle = "Incomplete field(s) at Company Details"
    messageError = `<ul class="text-left">
        ${errors.companyName ? "<li>" + errors.companyName.message + "</li> " : ""}

        ${errors.locationHelpOne ? "<li>" + errors.locationHelpOne.message + "</li> " : ""}

        ${errors.companyEmailAddress ? "<li>" + errors.companyEmailAddress.message + "</li> " : ""}
      </ul>`

    $("#collapseCompanyDetails").collapse("show")
  }

  Swal.fire({
    title: messageTitle,
    html: messageError,
    showCloseButton: true,
    icon: "error",
    confirmButtonColor: "#0073c3",
    confirmButtonText: "Ok!",
  })
}

export { schemaAdvanceSearch, viewErrorAdvancedSearch }
