import React from "react"
import boxChapUrl from "../../images/BoxChpa.png"

const WhoWeAre = () => {
  return (
    <section className="py-5" id="whoWeAreWho">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <h1 className="mb-4 title-who-we-are">Who We Are</h1>
            <p className="mb-4 p-chpa" data-aos="zoom-in">
              The search engine that gives you an overview of all shipping line routes, from the industry. We also provide historical data, on
              reliability and viability, to help you make your final choice. You can count on Chalupa to be your consultant when it comes to shipping.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 col-12 box-position-chpa">
            <img className="box-chpa" src={boxChapUrl} alt="Decoration Box Chalupa" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre
