import React from "react"

const FollowUs = () => {
  return (
    <div className="col-lg-6 col-md-12 col-12 footer-social">
      <h3 className="title-follow-us">FOLLOW US</h3>
      <a target="_blank" href="https://www.facebook.com/" rel="noreferrer noopener">
        <i className="fa fa-facebook-square"></i>
      </a>
      <a target="_blank" href="https://www.instagram.com/chalupaship/" rel="noreferrer noopener">
        <i className="fa fa-instagram"></i>
      </a>
    </div>
  )
}

export default FollowUs
