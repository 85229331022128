import React, { useContext } from "react"
import ReactMarkdown from "react-markdown"

// Context
import HomePageContext from "../../context/homePageContext"

// Components
import Modal from "../Modal"

// Constants
import termsAndConditions from "../../constants/termsAndConditions"

const TermsModal = () => {
  const { setAcceptTermsAndConditions, isAcceptTermsAndConditions, isShowTermsAndConditions, handleClosedTermsAndConditionsModal } = useContext(
    HomePageContext
  )

  const handleAcceptTermsAndConditions = () => {
    setAcceptTermsAndConditions(true)
    handleClosedTermsAndConditionsModal("close")
  }

  return (
    <Modal
      idModal="terms-conditions-modal"
      classModal="bd-modal-chpa"
      classTypeModal="modal-xl"
      ariaLabelledby="terms-conditions"
      isShow={isShowTermsAndConditions}
      onButtonClick={handleClosedTermsAndConditionsModal}
    >
      <div className="modal-content">
        <div className="modal-header-chpa">
          <h5 className="modal-title-chpa">TERMS AND CONDITIONS</h5>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ReactMarkdown className="terms-and-conditions">{termsAndConditions}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer-chpa terms-and-conditons">
          {isAcceptTermsAndConditions === false && (
            <button type="submit" className="btn button-chpa button-chpa-custom" onClick={handleAcceptTermsAndConditions}>
              Accept Terms and Conditions
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default TermsModal
