import React, { useContext, useRef, useState, useEffect } from "react"

// Components
import Modal from "../Modal"

const IntroVideoModal = () => {  
  const [showIntoVideoModal, setShowIntoVideoModal] = useState(false);

  useEffect(() => {
    setShowIntoVideoModal(!localStorage.getItem("showIntoVideoModal"));
  }, []);

  const iframeRef = useRef(null);
  const handleClose = () => {
    iframeRef.current.remove();
    setShowIntoVideoModal(false);
    localStorage.setItem('showIntoVideoModal', 'false');
  }

  return (
    <Modal
      idModal="intro-video-modal"
      classModal="intro-video-modal"
      classTypeModal="modal-xl"
      isShow={showIntoVideoModal}
      onButtonClick={handleClose}
    >
      <div className="intro-video-modal-content">
        <div className="row w-100">
          <div className="col-12 videoWrapper" onClick={e => e.stopPropagation()}>
            <iframe
              ref={iframeRef}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/BGnxdns8wX4?autoplay=1"
              allow="autoplay; encrypted-media"
              allowFullScreen>
            </iframe>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IntroVideoModal
