import React, { useEffect, useContext } from "react"
import $ from "jquery"

// Context
import HomePageContext from "../../context/homePageContext"

// Hooks
import useScrollPosition from "../../hook/useScrollPositions"

const ButtonToGoTop = () => {
  const [scroll, setScroll] = React.useState(0)

  const { isAcceptTermsAndConditions, handleOpenAcceptTermsAndConditionsModal } = useContext(HomePageContext)

  useEffect(() => {
    if (scroll >= 1200 && isAcceptTermsAndConditions === false) {
      handleOpenAcceptTermsAndConditionsModal()
    }

    window.onscroll = () => {
      if (scroll > 200) {
        $(".up-chpa").slideDown(300)
      } else {
        $(".up-chpa").slideUp(300)
      }
    }
  }, [isAcceptTermsAndConditions, scroll])

  useScrollPosition(({ currentPosition }) => {
    setScroll(currentPosition.y)
  })

  const handleClick = () => {
    $("body, html").animate(
      {
        scrollTop: "0px",
      },
      300
    )
  }

  return (
    <span className="up-chpa" onClick={handleClick} role="button" tabIndex={0} onKeyDown={() => {}}>
      <span className="fa-stack color-up-chpa">
        <i className="fa fa-circle fa-stack-2x"></i>
        <i className="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
      </span>
    </span>
  )
}

export default ButtonToGoTop
