import React, { useContext, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import $ from "jquery"

// Constants
import { REQUIRED_TERMS_AND_CONDITIONS } from "../../../../constants/tooltips"

// Context
import SearchContext from "../../../../context/searchContext"
import HomePageContext from "../../../../context/homePageContext"

const ShowAdvancedButton = ({ isLoading }) => {
  const spanRef = useRef(null)
  const { setResultSearchModal, setShowAdvancedModal } = useContext(SearchContext)
  const { isAcceptTermsAndConditions, handleOpenAcceptTermsAndConditionsModal } = useContext(HomePageContext)

  useLayoutEffect(() => {
    const spanDOM = $(spanRef.current)

    if (isAcceptTermsAndConditions) {
      spanDOM.removeAttr("type").attr("type", "submit")
    } else {
      spanDOM.removeAttr("type").attr("type", "button")
    }
  }, [isAcceptTermsAndConditions])

  const handleOpenAdvancedSearch = () => {
    if (!isAcceptTermsAndConditions) {
      handleOpenAcceptTermsAndConditionsModal()
    }
    if (isLoading === false && isAcceptTermsAndConditions) {
      setResultSearchModal((oldData) => ({
        ...oldData,
        isAdvancedSearch: true
      }))

      setShowAdvancedModal(true)
    }
  }

  return (
    <span ref={spanRef} className="search-chpa" role="button" tabIndex="0" onClick={handleOpenAdvancedSearch}
          onKeyDown={() => {
          }}>
      &gt; Advanced Search
    </span>
  )
}

ShowAdvancedButton.propTypes = {
  isLoading: PropTypes.bool.isRequired
}

export default ShowAdvancedButton
