import React, { useContext } from "react"

import Modal from "../../Modal"

// Context
import DiscoverContext from "../../../context/discoverContext"
import Social from "./Social"

const ModalContainer = () => {
  const { handleCloseModal, data, isShow } = useContext(DiscoverContext)
  const { url, social } = data

  return (
    <Modal idModal="containerShippingModal" ariaLabelledby="containerShipping" isShow={isShow} onButtonClick={handleCloseModal}>
      <div className="modal-content col-12 border-st-chpa border-st-chpa-mb">
        <div className="modal-header">
          <h5 className="modal-title">More Information</h5>
          <button type="button" className="close-chpa" aria-label="Close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="container-social-chpa">
            {social.map(({ url, name, socialClass }, index) => (
              <Social key={index} url={url} name={name} socialClass={socialClass} />
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <span className="subtittle-modal-chpa">Page Link :</span>
          <div className="row mt-2">
            <input className="col-10 inpt-social-chpa" placeholder={url} disabled />
            <a className="btn btn-clone-chpa" href={url} target="_blank" title="Link Page" role="button" rel="noreferrer noopener">
              <i className="fa fa-external-link fa-1x clone-chpa"></i>
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ModalContainer.propTypes = {}

export default ModalContainer
