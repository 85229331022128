import React from "react"
import { Link } from "gatsby"

import imgLogoChilltepe from "../../../images/LogoChilltepe.png"

const FooterBottom = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-12 footer-copyright">
          <p>
            Copyright &copy; 2020 <Link to="#">Chalupa - All rights reserved</Link>
          </p>
        </div>
        <div className="col-lg-6 col-md-12 col-12 footer-logo">
          <p>Powered by</p>
          <a href="https://chilltepe.com/" target="_blank" rel="noreferrer noopener">
            <img className="footer-copyrights-img" src={imgLogoChilltepe} alt="Chilltepe logo" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterBottom
