import React, { useContext } from "react"
import ReactMarkdown from "react-markdown"

// Context
import HomePageContext from "../../context/homePageContext"

// Components
import Modal from "../Modal"

// Constants
import termsAndConditions from "../../constants/termsAndConditions"
import { REQUIRED_TERMS_AND_CONDITIONS } from "../../constants/tooltips"

const AcceptTermsModal = () => {
  const {
    setAcceptTermsAndConditions,
    isAcceptTermsAndConditions,
    isShowAcceptTermsAndConditions,
    handleClosedAcceptTermsAndConditionsModal,
    handleOpenTermsAndConditionsModal
  } = useContext(
    HomePageContext
  )

  const handleAcceptTermsAndConditions = () => {
    setAcceptTermsAndConditions(true)
    handleClosedAcceptTermsAndConditionsModal("close")
  }

  const handleOpenTermsAndConditions = () => {
    handleClosedAcceptTermsAndConditionsModal("close")

    setTimeout(() => {
      handleOpenTermsAndConditionsModal()
    }, 400);
  }


  return (
    <Modal
      idModal="accept-terms-conditions-modal"
      classModal="bd-modal-chpa modal-accept-terms-condition"
      classTypeModal="modal-xl"
      ariaLabelledby="terms-conditions"
      isShow={isShowAcceptTermsAndConditions && !isAcceptTermsAndConditions}
      onButtonClick={handleClosedAcceptTermsAndConditionsModal}
    >
      <div className="modal-content">
        <div className="modal-header-chpa">
          <h5 className="modal-title-chpa">TERMS AND CONDITIONS</h5>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="row justify-content-center">
              <div>
                <p>
                  {REQUIRED_TERMS_AND_CONDITIONS}
                  <a className="terms-and-conditons-copy" onClick={handleOpenTermsAndConditions}>
                    Terms and Conditions of the page.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {isAcceptTermsAndConditions === false && (
          <div className="modal-footer-chpa terms-and-conditons">
            <button type="submit"
                    className="btn button-chpa button-chpa-custom mr-4"
                    onClick={handleAcceptTermsAndConditions}>
              Accept
            </button>
            <button type="submit" className="btn button-chpa-declined button-chpa-custom"
                    onClick={handleClosedAcceptTermsAndConditionsModal}>
              Declined
            </button>
          </div>
        )}

      </div>
    </Modal>
  )
}

export default AcceptTermsModal
