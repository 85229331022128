import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Cookies } from "react-cookie"

// Context
import HomePage from "../context/homePageContext"

// Components
import ButtonToGoTop from "../components/ButtonToGoTop"
import { Footer, Main, WhoWeAre, Banner, Discover, ContactUs } from "../components/Sections"
import TermsModal from "../components/TermsAndConditions/TermsModal"
import AcceptTermsModal from "../components/TermsAndConditions/AcceptTermsModal"
import IntroVideoModal from "../components/IntroVideo/IntroVideoModal"

const IndexPage = () => {
  const cookies = new Cookies()

  const [isAcceptTermsAndConditions, setAcceptTermsAndConditions] = useState(
    cookies.get('chaterms') === 'true'
  );


  const [isShowTermsAndConditions, setIsShowTermsAndConditions] = useState(false)
  const [isShowAcceptTermsAndConditions, setIsShowAcceptTermsAndConditions] = useState(false)
  const [uncheckedManually, setUncheckedManually] = useState(false)


  useEffect(() => {
    if (isAcceptTermsAndConditions) {
      cookies.set('chaterms', 'true', { path: '/' });
    } else {
      cookies.set('chaterms', 'false', { path: '/' });
    }
  }, [isAcceptTermsAndConditions]);

  const handleUncheckedManually = () => {
    setUncheckedManually(true)
  }


  const handleClosedTermsAndConditionsModal = () => {
    setIsShowTermsAndConditions(false)
  }

  const handleOpenTermsAndConditionsModal = () => {
    setIsShowTermsAndConditions(true);
  }

  const handleClosedAcceptTermsAndConditionsModal = () => {
    setIsShowAcceptTermsAndConditions(false)
  }

  const handleOpenAcceptTermsAndConditionsModal = () => {
    setIsShowAcceptTermsAndConditions(true)
  }



  return (
    <HomePage.Provider
      value={{
        handleClosedTermsAndConditionsModal,
        setIsShowTermsAndConditions,
        setAcceptTermsAndConditions,
        isAcceptTermsAndConditions,
        isShowTermsAndConditions,
        uncheckedManually,
        handleUncheckedManually,
        handleOpenTermsAndConditionsModal,
        isShowAcceptTermsAndConditions,
        handleClosedAcceptTermsAndConditionsModal,
        handleOpenAcceptTermsAndConditionsModal
      }}
    >
        <Layout>
          <SEO title="Home" />
          <Main />
          <WhoWeAre />
          <Banner />
          <Discover />
          <ContactUs />
          <Footer />
          <TermsModal />
          <AcceptTermsModal />
          <IntroVideoModal />
          <ButtonToGoTop />
        </Layout>

    </HomePage.Provider>
  )
}

export default IndexPage
