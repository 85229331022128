import React from "react"
import PropTypes from "prop-types"

const Social = ({ url, name, socialClass }) => {
  return (
    <div className="smd">
      <a href={url} target="_blank" rel="noreferrer noopener">
        <i className={`fa ${socialClass} fa-3x`}></i>
      </a>
      <p className="name-social-chpa">{name}</p>
    </div>
  )
}

Social.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  socialClass: PropTypes.string.isRequired,
}

export default Social
