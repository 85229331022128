import { format, parse } from "date-fns"
import { shippingCompanies, listHaulage } from "../constants"

const formatDataShippingCompanies = ({ dataShippingCompanies, orderType, orderBy, haulageType, dataSearch, typeShippingCompany }) => {
  const rowShippingCompany = []
  const rowDaysString = []
  const rowDirect = []
  const rowVessel = []
  const rowVesselDepertureDate = []
  const rowOneTransshipment = []
  const rowTwoTransshipment = []
  const rowLoadingPorts = []
  const rowDischargePorts = []

  let data = []
  let filterShippingCompanies = shippingCompanies
  let filterShippingHaulage = {}
  let haulageTypeSelect = haulageType
  const { haulageFound, shippingFound } = getHaulageShippingFound(dataShippingCompanies)

  // console.log({shippingFound}, typeShippingCompany)

  // Check if haulage and shipping company not found
  if (haulageFound.length === 0) {
    return null
  }

  // Check if haulage and shipping company found or type haulage not found
  if (haulageFound.length === 1 || !haulageType) {
    filterShippingHaulage = shippingFound[0]
    haulageTypeSelect = haulageFound[0].name
  }

  if (haulageFound.length > 1 && haulageType) {
    filterShippingHaulage = shippingFound.find((item) => item.name === haulageType)
    if (!filterShippingHaulage) return null
    haulageTypeSelect = haulageType
  }

  // Filter type shipping company
  if (typeShippingCompany !== "ALL") {
    filterShippingCompanies = shippingCompanies.filter((item) => item.name === typeShippingCompany)
  }

  for (const shippingCompany of filterShippingCompanies) {
    for (const element of filterShippingHaulage[shippingCompany.id]) {
      data.push({
        shippingCompany: shippingCompany.name,
        shippingCompanyURL: shippingCompany.URL,
        days: parseInt(element.days),
        daysString: element.daysString,
        vessel: element.vessel,
        vesselDepertureDate: parse(element.vesselDepartureDate, "MM-dd-yyyy", new Date()),
        vesselDepertureDateString: format(parse(element.vesselDepartureDate, "MM-dd-yyyy", new Date()), "MMMM dd, yyyy"),
        direct: element.direct,
        oneTransshipment: element.oneTransshipment,
        twoTransshipment: element.twoTransshipment,
        loadingPort: element.loadingPort,
        dischargePort: element.dischargePort,
      })
    }
  }

  // Order
  data = getOrderFirstGrade(data, orderType, orderBy)
  data = getOrderSecondGrade(data, orderType)

  data.forEach((element) => {
    rowShippingCompany.push({ name: element.shippingCompany, url: element.shippingCompanyURL })
    rowDaysString.push(element.daysString)
    rowVessel.push(element.vessel)
    rowVesselDepertureDate.push(element.vesselDepertureDateString)
    rowDirect.push(element.direct)
    rowOneTransshipment.push(element.oneTransshipment)
    rowTwoTransshipment.push(element.twoTransshipment)
    rowLoadingPorts.push(element.loadingPort)
    rowDischargePorts.push(element.dischargePort)
  })

  return {
    data,
    filterShippingHaulage,
    haulageFound,
    haulageType: haulageTypeSelect,
    rowShippingCompany,
    rowDaysString,
    rowVessel,
    rowVesselDepertureDate,
    rowDirect,
    rowOneTransshipment,
    rowTwoTransshipment,
    rowLoadingPorts,
    rowDischargePorts,
  }
}

const getHaulageShippingFound = function (shippingCompanies) {
  const haulageFound = []
  const shippingFound = []

  listHaulage.forEach((haulage) => {
    const { name } = haulage
    if (shippingCompanies[name].total !== 0) {
      shippingCompanies[name].name = name
      shippingFound.push(shippingCompanies[name])
      haulageFound.push(haulage)
    }
  })

  return { shippingFound, haulageFound }
}

const getOrderFirstGrade = function (data, orderType, orderBy) {
  let orderData = []

  if (orderType === "day") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => a.days - b.days)
    } else {
      orderData = data.sort((a, b) => b.days - a.days)
    }
  } else if (orderType === "date") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => a.vesselDepertureDate - b.vesselDepertureDate)
    } else {
      orderData = data.sort((a, b) => b.vesselDepertureDate - a.vesselDepertureDate)
    }
  } else if (orderType === "loadingPort") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => a.loadingPort - b.loadingPort)
    } else {
      orderData = data.sort((a, b) => b.loadingPort - a.loadingPort)
    }
  } else if (orderType === "dischargePort") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => {
        const wordA = a.dischargePort.toUpperCase()
        const wordB = b.dischargePort.toUpperCase()

        return wordA < wordB ? -1 : wordA > wordB ? 1 : 0
      })
    } else {
      orderData = data.sort((a, b) => {
        const wordA = a.dischargePort.toUpperCase()
        const wordB = b.dischargePort.toUpperCase()

        return wordA > wordB ? -1 : wordA < wordB ? 1 : 0
      })
    }
  }

  return orderData
}

const getOrderSecondGrade = function (data, orderType) {
  let orderData = []
  let uniqueData = []

  if (orderType === "day") {
    uniqueData = [...new Set(data.map((item) => item.days))]

    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.days === unique).sort((a, b) => a.vesselDepertureDate - b.vesselDepertureDate))
    }
  } else if (orderType === "date") {
    uniqueData = [...new Set(data.map((item) => item.vesselDepertureDateString))]
    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.vesselDepertureDateString === unique).sort((a, b) => a.days - b.days))
    }
  } else if (orderType === "loadingPort") {
    uniqueData = [...new Set(data.map((item) => item.loadingPort))]
    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.loadingPort === unique).sort((a, b) => a.days - b.days))
    }
  } else if (orderType === "dischargePort") {
    uniqueData = [...new Set(data.map((item) => item.dischargePort))]
    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.dischargePort === unique).sort((a, b) => a.days - b.days))
    }
  }

  return orderData
}

export { formatDataShippingCompanies }
