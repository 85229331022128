import React, { useEffect } from "react"
import PropTypes from "prop-types"
import $ from "jquery"

const Modal = ({ idModal, classModal, classTypeModal, ariaLabelledby, isShow, onButtonClick, children }) => {

  useEffect(() => {
    $(`#${idModal}`).modal(isShow ? "show" : "hide")
  }, [idModal, isShow])

  useEffect(() => {
    $(`#${idModal}`).on("hide.bs.modal", () => {
      onButtonClick("close")
    })

    return () => {
      $(`#${idModal}`).off("hide.bs.modal")
    }
  }, [idModal, onButtonClick])

  return (
    <div
      className={`modal fade ${classModal || " "}`}
      id={idModal}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={ariaLabelledby}
      aria-hidden="true"
    >
      <div className={`modal-dialog ${classTypeModal || "modal-dialog-centered"} role="dialog"`}>{children}</div>
    </div>
  )
}

Modal.propTypes = {
  idModal: PropTypes.string.isRequired,
  ariaLabelledby: PropTypes.string.isRequired,
  classModal: PropTypes.string,
  classTypeModal: PropTypes.string,
  isShow: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Modal
