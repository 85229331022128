import React, { useState, useRef, useContext } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Context
import ContactUsContext from "../../../context/contactUsContext"

import sendEmail from "../../../services/sendEmail"
import { schemaContactUs, viewErrorContactUs } from "../../../validations"

const Form = () => {
  const { setModal } = useContext(ContactUsContext)

  const recaptchaRef = useRef()
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaContactUs),
    shouldFocusError: false,
  })

  const [isLoading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    data.token = await recaptchaRef.current.executeAsync()

    setLoading(true)
    const { success, message } = await sendEmail(data)

    setModal({
      isSuccess: success,
      isShow: true,
      message: message,
    })

    if (success) reset()

    recaptchaRef.current.reset()
    setLoading(false)
  }

  const onError = (errors) => {
    viewErrorContactUs(errors)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="p-md-5 p-4 mb-5 contact-form contact-form-chpa needs-validation shadow" noValidate>
        <div className="form-row ">
          {/* Name */}
          <div className="col-md-12 mb-3">
            <input type="text" className="form-control" ref={register()} name="name" placeholder="Name *" />

            <div className="invalid-feedback invalid-chpa" style={{ display: errors.name ? "inline" : "none" }}>
              {errors.name && errors.name.message}
            </div>

            <div className="valid-feedback"></div>
          </div>

          {/* Email */}
          <div className="col-lg-12 col-md-12 col-12 form-group">
            <input type="email" className="form-control" name="email" placeholder="Email Addres *" ref={register()} />
            <div className="invalid-feedback invalid-chpa" style={{ display: errors.email ? "inline" : "none" }}>
              {errors.email && errors.email.message}
            </div>

            <div className="valid-feedback"></div>
          </div>

          {/* Subject */}
          <div className="col-lg-12 col-md-12 col-12 form-group">
            <input type="text" className="form-control" name="subject" placeholder="Subject *" ref={register()} />
            <div
              className="invalid-feedback invalid-chpa"
              style={{
                display: errors.subject ? "inline" : "none",
              }}
            >
              {errors.subject && errors.subject.message}
            </div>

            <div className="valid-feedback"></div>
          </div>

          {/* Message */}
          <div className="col-lg-12 col-md-12 col-12 form-group">
            <textarea className="form-control" name="message" placeholder="Message *" rows="4" ref={register()}></textarea>
            <div
              className="invalid-feedback invalid-chpa"
              style={{
                display: errors.message ? "inline" : "none",
              }}
            >
              {errors.message && errors.message.message}
            </div>

            <div className="valid-feedback"></div>
          </div>

          <div className="col-lg-12 col-md-12 col-12 align-buttons-chpa">
            {isLoading ? (
              <div className="text-center" style={{ width: "43.710px", height: "23.631px", padding: "8px 16px", margin: "22px 0px" }}>
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <button type="submit" className="btn button-chpa-send">
                Send
              </button>
            )}
          </div>
        </div>
      </form>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.KEY_RECAPTCHA} size="invisible" />
    </>
  )
}

export default Form
