import * as yup from "yup"
import Swal from "sweetalert2"

import { messageError } from "../constants"

// const existsLocation = (value) => {
//   return countries.indexOf(value) !== -1
// }

// const isNoSameLocation = (dropOffLocation, pickupLocation = "") => {
//   return pickupLocation.trim() !== dropOffLocation.trim()
// }

const schemaSimpleSearch = yup.object().shape({
  pickupLocation: yup
    .string()
    .required(messageError.FORM_SIMPLE.PICKUP_LOCATION_REQUIRED),

  dropOffLocation: yup
    .string()
    .required(messageError.FORM_SIMPLE.DROP_OFF_LOCATION_REQUIRED),

  searchStartDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required(messageError.FORM_SIMPLE.SEARCH_START_DATE_REQUIRED),
})

const componentsForm = ["pickupLocation", "dropOffLocation", "searchStartDate"]

const viewErrorSimpleSearch = (errors) => {
  let messageError = '<ul class="text-left">'

  componentsForm.forEach((item) => {
    messageError += errors[item] ? `<li>${errors[item].message}</li>` : ""
  })

  messageError += "</ul>"

  Swal.fire({
    title: "Some required fields are missing",
    html: messageError,
    showCloseButton: true,
    icon: "error",
    confirmButtonColor: "#0073c3",
    confirmButtonText: "Ok!",
  })
}

export { schemaSimpleSearch, viewErrorSimpleSearch }
