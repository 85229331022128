import React, { useContext } from "react"

// Assets
import imgLogoChalupa from "../../../images/LogoChalupaNo2.png"

// Context
import HomePageContext from "../../../context/homePageContext"

const ContactUs = () => {
  const { setIsShowTermsAndConditions } = useContext(HomePageContext)

  const handleShowTermsAndConditionsModal = () => {
    setIsShowTermsAndConditions(true)
  }

  return (
    <div className="col-lg-6 col-md-12 col-12 footer-contact">
      <img className="logo-footer" src={imgLogoChalupa} alt="Logo Chalupa" />
      <h3 className="title-follow-us">CONTACT US</h3>
      <p>
        <i className="fa fa-envelope"></i> Email:
        <a href="mailto:contactus@chalupa.com">contactus@chalupa.com</a>
        <span role="button" tabIndex={0} className="footer-terms-and-conditons" onClick={handleShowTermsAndConditionsModal} onKeyUp={() => {}}>
          Terms & Conditions
        </span>
      </p>
    </div>
  )
}

export default ContactUs
