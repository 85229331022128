import React, { useContext } from "react"
import $ from "jquery"
import classNames from "classnames"

// Contexts
import SearchContext from "../../../context/searchContext"

// Contansts
import { shippingCompanies } from "../../../constants"

const SectionScrollTransitTime = () => {
  const { resultSearchModal, handleChangeOrder, handleChangeHaulage } = useContext(SearchContext)
  const { data } = resultSearchModal
  const { originData, orderType, orderBy, typeShippingCompany, haulageFound, haulageType } = data

  const uniqueShippingCompanies = []

  shippingCompanies.forEach((item) => {
    const shippingCompanyHaulage = originData[haulageType]

    if (!shippingCompanyHaulage) return false

    const shippingCompany = shippingCompanyHaulage[item.id]
    if (shippingCompany && shippingCompany.length) {
      uniqueShippingCompanies.push(item.name)
    }
  })

  const handleScrollTable = (value) => {
    $(".js-pscroll").animate(
      {
        scrollLeft: `${value}px`,
      },
      "slow"
    )
  }

  return (
    <>
      <div className={classNames(haulageFound.length > 1 ? "col-lg-3" : "col-lg-4", "col-md-12", "col-12")}>
        <div className="back-buttons-chpa mb-2 mb-lg-0 justify-content-center justify-content-lg-start">
          <button type="button" className="btn btn-link scroll-btn" onClick={() => handleScrollTable("-=300")}>
            &#60; Shorter Transit Time
          </button>
        </div>
      </div>

      {haulageFound.length > 1 && (
        <div className="col-lg-3 col-md-12 col-12 text-center">
          <select
            name="containerHaulage"
            className="form-control"
            onChange={(e) => {
              handleChangeHaulage(e.target.value)
            }}
            onBlur={() => {}}
            value={haulageType ? haulageType : ""}
          >
            {haulageFound.map(({ name, nameText }) => {
              return (
                <option value={name} key={name}>
                  {nameText}
                </option>
              )
            })}
          </select>
        </div>
      )}

      <div className={classNames(haulageFound.length > 1 ? "col-lg-3" : "col-lg-4", "col-md-12", "col-12", "text-center")}>
        <select
          name="containerType"
          className="form-control"
          onChange={(e) => handleChangeOrder(orderType, orderBy, e.target.value)}
          onBlur={() => {}}
          value={typeShippingCompany}
        >
          <option value="ALL" key="all">
            All
          </option>
          {uniqueShippingCompanies.map((unique) => {
            return (
              <option value={unique} key={unique}>
                {unique}
              </option>
            )
          })}
        </select>
      </div>

      <div className={classNames(haulageFound.length > 1 ? "col-lg-3" : "col-lg-4", "col-md-12", "col-12")}>
        <div className="next-buttons-chpa mt-2 mt-lg-0 justify-content-center justify-content-lg-end">
          <button type="button" className="btn btn-link scroll-btn" onClick={() => handleScrollTable("+=300")}>
            Longer Transit Time &#62;
          </button>
        </div>
      </div>
      <br />
      <br />
    </>
  )
}

export default SectionScrollTransitTime
