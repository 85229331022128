import React, { useState } from "react"

// Components
import Button from "./Button"
import ModalContainer from "./ModalContainer"

// Context
import DiscoverContext from "../../../context/discoverContext"

// Constants
import { discover } from "../../../constants"

const Discover = () => {
  const { ONE_ROW_DISCOVER, TWO_ROW_DISCOVER, THREE_ROW_DISCOVER } = discover

  const [modal, setModal] = useState({
    data: {
      url: "",
      social: [],
    },
    isShow: false,
  })

  const handleCloseModal = (type) => {
    if (type === "close") {
      setModal((oldData) => ({
        ...oldData,
        isShow: false,
      }))
    }
  }

  const handleOpenModal = ({ url, social }) => {
    setModal({
      data: {
        url,
        social,
      },
      isShow: true,
    })
  }

  return (
    <section className="py-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-md-12 col-12 design-position-chpa">
            <div className="g-banner-design">
              <div className="g-banner-img-design"></div>
            </div>
          </div>
          <div className="col-lg-9 col-md-12 col-12">
            <h1 className="mb-4 title-discover-chpa-one">
              Discover the<b> 10 largest </b>
            </h1>
            <h1 className="mb-4 title-discover-chpa">container shipping companies</h1>
            <h1 className="mb-4 title-discover-chpa">in the world</h1>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <DiscoverContext.Provider value={{ handleOpenModal, handleCloseModal, ...modal }}>
          <div className="row discover-position-chpa">
            {ONE_ROW_DISCOVER.map(({ number, title, description, cardClass, numberClass, data }, index) => {
              return (
                <Button
                  key={index}
                  number={number}
                  title={title}
                  description={description}
                  cardClass={cardClass}
                  numberClass={numberClass}
                  data={data}
                />
              )
            })}
          </div>

          <div className="row discover-position-chpa">
            {TWO_ROW_DISCOVER.map(({ number, title, description, cardClass, numberClass, data }, index) => {
              return (
                <Button
                  key={index}
                  number={number}
                  title={title}
                  description={description}
                  cardClass={cardClass}
                  numberClass={numberClass}
                  data={data}
                />
              )
            })}
          </div>

          <div className="row discover-position-chpa">
            {THREE_ROW_DISCOVER.map(({ number, title, description, cardClass, numberClass, data }, index) => {
              return (
                <Button
                  key={index}
                  number={number}
                  title={title}
                  description={description}
                  cardClass={cardClass}
                  numberClass={numberClass}
                  data={data}
                />
              )
            })}
          </div>

          <ModalContainer />
        </DiscoverContext.Provider>
      </div>
    </section>
  )
}

export default Discover
