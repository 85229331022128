import React from "react"

// Constants
import { HIDE_SOCIAL_COMPONENT } from "../../../constants/global"

// Components
import FollowUs from "./FollowUs"
import ContactUs from "./ContactUs"
import FooterBottom from "./FooterBottom"

// Style
import "./style.css"

const Footer = () => {
  return (
    <footer>
      <div className="footer-top-main">
        <div className="footer-top footer-top-mv">
          <div className="container">
            <div className="row">
              <ContactUs />

              {HIDE_SOCIAL_COMPONENT === false ? <FollowUs /> : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <FooterBottom />
      </div>
    </footer>
  )
}

export default Footer
