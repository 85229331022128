import React from 'react'

const Loading = () => {
  return (
    <div className="text-center" style={{ width: "156.21px", height: "39.06px" }}>
      <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Loading