import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from "react";

const GoogleCaptchaWrapper = ({children}) => {
    const recaptchaKey = process.env.KEY_RECAPTCHAV3;
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={recaptchaKey}
        >
            {children}
        </GoogleReCaptchaProvider>
    );
}

export default GoogleCaptchaWrapper