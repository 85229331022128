import React, { useContext, useRef, useLayoutEffect } from "react"
import $ from "jquery"

// Constants
import { REQUIRED_TERMS_AND_CONDITIONS } from "../../../../constants/tooltips"

// Context
import HomePageContext from "../../../../context/homePageContext"

const Button = () => {
  const buttonRef = useRef(null)
  const { isAcceptTermsAndConditions, handleOpenAcceptTermsAndConditionsModal } = useContext(HomePageContext)

  useLayoutEffect(() => {
    const buttonDOM = $(buttonRef.current)

    if (isAcceptTermsAndConditions) {
      buttonDOM.removeAttr("type").attr("type", "submit")
    } else {
      buttonDOM.removeAttr("type").attr("type", "button");
    }
  }, [isAcceptTermsAndConditions])

  const handleClick = () => {
    if (!isAcceptTermsAndConditions) {
      handleOpenAcceptTermsAndConditionsModal();
    }
  };

  return (
    <button ref={buttonRef} type="button" name="getRoutesOne" className="btn button-chpa" tabIndex="0" onClick={handleClick}>
      Get Routes Now!
    </button>
  )
}

export default Button
