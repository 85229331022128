import * as yup from "yup"
import Swal from "sweetalert2"
import { messageError } from "../constants"

const schemaContactUs = yup.object().shape({
  name: yup.string().required(messageError.FORM_CONTACT_US.NAME_REQUIRED),

  email: yup
    .string()
    .required(messageError.FORM_CONTACT_US.EMAIL_REQUIRED)
    .email(messageError.FORM_CONTACT_US.EMAIL_INVALID),

  subject: yup.string().required(messageError.FORM_CONTACT_US.SUBJECT_REQUIRED),

  message: yup.string().required(messageError.FORM_CONTACT_US.MESSAGE_REQUIRED),
})

const viewErrorContactUs = (errors) => {
  Swal.fire({
    title: "Some required fields are missing",
    html: "Please check and complete de information required",
    icon: "error",
    confirmButtonColor: "#0073c3",
    confirmButtonText: "Ok!",
  })
}

export { schemaContactUs, viewErrorContactUs }
