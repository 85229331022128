import React, { useState, useRef, useEffect, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { addWeeks, format } from "date-fns";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";

// Context
import SearchContext from "../../../../context/searchContext";

// Components
import Button from "./Button";
import Loading from "./Loading";
import ShowAdvancedButton from "./ShowAdvancedButton";

// Helpers and Services
import { formatDataShippingCompanies } from "../../../../helpers/functionSearch";
import getSimpleSearch from "../../../../services/getSimpleSearch";

// Constants
import { messageError } from "../../../../constants";
import { SUGGESTION_SIMPLE_SEARCH } from "../../../../constants/tooltips";

// Validations
import { schemaSimpleSearch, viewErrorSimpleSearch } from "../../../../validations";
import HomePageContext from "../../../../context/homePageContext";
import { getLocations, getLocationsHead } from "../../../../services/getLocations";
import { orderDataFetch, orderData } from "../../../../helpers/orderData";
import { Cookies } from "react-cookie";

const Form = () => {
  const tooltipSearchSimple = useRef();
  const recaptchaRef = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const cookies = new Cookies();

  const [stateForm, setStateForm] = useState({
    isLoading: false,
    isApplyValidate: false,
  });

  const { ERROR_EMPTY_SEARCH } = messageError;
  const { isLoading, isApplyValidate } = stateForm;

  const { setResultSearchModal, setNoResultSearchModal } = useContext(SearchContext);
  const { isAcceptTermsAndConditions } = useContext(HomePageContext);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [op1, setOp1] = useState([]);
  const [op2, setOp2] = useState([]);

  const { handleSubmit, errors, control, register } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaSimpleSearch),
    shouldFocusError: false,
  });

  const locationsOrigin = async (text) => {
    if (text.length > 3) {
      const data = await locations({ text });
      const order = orderData({data});
      setOp1(data);
      setOptions1(order);
      } else {
      setOp1([]);
      setOptions1([]);
    }
  };

  const locationsDestination = async (text) => {
    if (text.length > 3) {
      const data = await locations({ text });
      const order = orderData({data});
      setOp2(data);
      setOptions2(order);
    } else {
      setOp2([]);
      setOptions2([]);
    }
  };

  const timeToken = () => {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (1 * 60 * 60 * 1000))

    return expirationDate
  }

  const headVerifyToken = async () => {
    const res = await getLocationsHead()
    if(res.code === 401) {

      try {
        const token = await executeRecaptcha('focusInput')
        cookies.set('locations-chalupa', token, { expires: timeToken() })
        
      } catch(e) {
        console.error(e)
      }
    } else {
      cookies.set('hash-locations-chalupa', res.hash, { expires: timeToken() })
    }
  }

  const locations = async ({text}) => {
    const hash = cookies.get('hash-locations-chalupa')
    if(!hash) {
      await headVerifyToken()
    }

    if(hash) {
      const { success, ...data } = await getLocations({ cityName: text, token: hash })
      return await data;
    } 

    return {}
  }

  useEffect(() => {
    $(tooltipSearchSimple.current).tooltip({
      title: SUGGESTION_SIMPLE_SEARCH,
      placement: "top",
    });
  }, []);

  const setStatusLoading = (isLoading) => {
    setStateForm((oldData) => ({
      ...oldData,
      isLoading,
    }));
  };

  const showNoResultSearchModal = (message) => {
    setNoResultSearchModal({
      isShow: true,
      isAdvancedSearch: false,
      message,
    });

    recaptchaRef.current.reset();
    setStatusLoading(false);
  };

  useEffect(() => {
    setSubmitEnable(isAcceptTermsAndConditions);
  }, [isAcceptTermsAndConditions]);

  const onSubmit = async (data) => {
    if (!isAcceptTermsAndConditions) {
      return;
    }
    const token = await recaptchaRef.current.executeAsync();

    setStatusLoading(true);

    const originLocation = data.pickupLocation;
    const destinationLocation = data.dropOffLocation;
    const searchStartDate = format(data.searchStartDate, "MM-dd-yyyy")
  
    const location = orderDataFetch({originPick: originLocation, destinationPick: destinationLocation, originData: op1, destinationData: op2})

    const res = await getSimpleSearch({
      originLocation,
      destinationLocation,
      searchStartDate,
      token,
      location
    });

    const { search, result, success, message } = res;

    // Error fatal
    if (success === false) {
      showNoResultSearchModal(message);
      return false;
    }

    const shippingCompanies = formatDataShippingCompanies({
      orderType: "date",
      orderBy: "desc",
      typeShippingCompany: "ALL",
      dataShippingCompanies: result,
      dataSearch: search,
    });

    // Empty Search
    if (shippingCompanies === null) {
      showNoResultSearchModal(ERROR_EMPTY_SEARCH);
      return false;
    }

    setResultSearchModal((oldData) => ({
      ...oldData,
      isShow: true,
      data: {
        originData: res.result,
        dataSearch: search,
        orderType: "date",
        orderBy: "desc",
        typeShippingCompany: "ALL",
        ...shippingCompanies,
      },
    }));

    recaptchaRef.current.reset();
    setStatusLoading(false);

    return true;
  };

  const onError = (errors) => {
    setStateForm((oldData) => ({
      ...oldData,
      isApplyValidate: true,
    }));

    viewErrorSimpleSearch(errors);
  };

  const handleClickHead = () => {
    const hash = cookies.get('hash-locations-chalupa')
    const token = cookies.get('locations-chalupa')
    if(!hash || hash === 'undefined' || !token) {
      headVerifyToken()
    }
  }

  return (
    <>
      <form className="needs-validation" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        <div
          ref={tooltipSearchSimple}
          style={{
            position: "absolute",
            top: 5,
            left: 10,
          }}
        >
          <i
            style={{
              fontSize: "1.5rem",
            }}
            className="text-white fa fa-exclamation-circle"
          ></i>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-3 order-lg-0 col-md-11 col-12 order-1 mb-lg-2 mb-3 form-group form-chpa-one" onClick={handleClickHead}>
            {/* Pickup Location */}
            <div className="form-chpa-search">
              <Controller
                control={control}
                name="pickupLocation"
                defaultValue=""
                render={({ onChange }) => (
                  <Typeahead
                    id="pickupLocation"
                    options={options1}
                    onInputChange={(text) => locationsOrigin(text || "")}
                    placeholder="&#xf041; Origin Port"
                    inputProps={{
                      className: classNames("form-control", "icon-chpa", errors.pickupLocation ? "is-invalid" : isApplyValidate ? "is-valid" : ""),
                      spellCheck: "false",
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <span className="rbt-text">
                        <Highlighter search={props.text}>{option}</Highlighter>
                      </span>
                    )}
                    onChange={(value) => {
                      onChange(value[0])
                    }}
                    onBlur={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )}
              />
            </div>
          </div>

          {/* Drop Off Location */}
          <div className="col-lg-3 order-lg-1 col-md-11 col-12 order-2 mb-lg-2 mb-3 form-group form-chpa-one" onClick={handleClickHead}>
            <div className="form-chpa-search">
              <Controller
                control={control}
                name="dropOffLocation"
                defaultValue=""
                render={({ onChange }) => (
                  <Typeahead
                    id="dropOffLocation"
                    options={options2}
                    onInputChange={(text) => locationsDestination(text || "")}
                    placeholder="&#xf041; Destination Port"
                    inputProps={{
                      className: classNames("form-control", "icon-chpa", errors.dropOffLocation ? "is-invalid" : isApplyValidate ? "is-valid" : ""),
                      spellCheck: "false",
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <span className="rbt-text">
                        <Highlighter search={props.text}>{option}</Highlighter>
                      </span>
                    )}
                    onChange={(value) => {
                      onChange(value[0])
                    }}
                    onBlur={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )}
              />
            </div>
          </div>

          {/* Search Departure Date */}
          <div className="col-lg-3 order-lg-2 col-md-11 col-12 order-3 mb-lg-2 mb-3 form-group form-chpa-one">
            <div className="form-chpa-search">
              <Controller
                control={control}
                name="searchStartDate"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    minDate={new Date()}
                    maxDate={addWeeks(new Date(), 8)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="&#xf133; Search Departure Date"
                    className={classNames("form-control", "icon-chpa", errors.searchStartDate ? "is-invalid" : isApplyValidate ? "is-valid" : "")}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                  />
                )}
              />
            </div>
          </div>

          {/* Link Modal Advance Search */}
          <div className="col-lg-3 order-lg-3 col-md-12 col-12 order-0 mb-0">
            <div className="text-right">
              <ShowAdvancedButton isLoading={isLoading} />
            </div>
          </div>

          {/* Input Get Routes Now */}
          <div className="col-lg-3 order-lg-5 offset-lg-9 col-md-11 col-12 order-5">
            <div className="align-chpa">{isLoading ? <Loading /> : <Button />}</div>
          </div>

          {/*<div className="col-lg-3 order-lg-5 offset-lg-9 col-md-11 col-12 order-5">*/}
          {/*  <div className="align-chpa">*/}
          {/*    <TermsAndConditionsCheckbox />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </form>
      <div
        style={{
          display: "none",
        }}
      >
        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.KEY_RECAPTCHA} size="invisible" />
      </div>
    </>
  );
};

export default Form;
