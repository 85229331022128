const getApi = (path, params, token) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  }

  const url = new URL(path)
  url.search = new URLSearchParams(params).toString()

  return request(url, "GET", headers)
}

const getApiLocations = (path) => {
  const headers = {
    credentials: 'include'
  }

  const url = new URL(path)
  url.method = 'HEAD'
  return head(url, headers)
}

const checkStatus = (res) => {
  return res.ok
}

const head = async (url, headers) => {
  const res = await fetch(url, headers)

  if (checkStatus(res)) {
    const data = await res.json()
    return { ...data, success: true }
  }
  switch (res.status) {
    case 400:
      return { success: false, message: "Oops! Invalid query parameter", code: 400 }
    case 401:
      return { success: false, message: "Oops! Invalid Token", code: 401 }
    case 500:
      return { success: false, message: "Oops! Serious problems with the API", code: 500 }
    default:
      return {
        success: false,
        message: "Oops! Unknown error has occurred, please contact the administrator",
        code: 500
      }
  }
}

const request = async (url, method, headers) => {
  const opt = { method, headers }
  const res = await fetch(url, opt)

  if (checkStatus(res)) {
    const data = await res.json()
    return { ...data, success: true }
  }
  switch (res.status) {
    case 400:
      return { success: false, message: "Oops! Invalid query parameter" }
    case 401:
      return { success: false, message: "Oops! Invalid Token" }
    case 500:
      return { success: false, message: "Oops! Serious problems with the API" }
    default:
      return {
        success: false,
        message: "Oops! Unknown error has occurred, please contact the administrator",
      }
  }
}

export { getApi, getApiLocations }
