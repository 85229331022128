import { getApi } from "../helpers/clientApi"

const getAdvancedSearch = async ({
  originLocation,
  originHaulage,
  destinationLocation,
  destinationHaulage,
  containerType,
  containerSize,
  containerQuantity,
  containerWeight,
  soc,
  commodity,
  dangerousCargo,
  searchStartDate,
  nameCompany,
  locationCompany,
  emailCompany,
  token,
  IMO,
  locations,
}) => {
  const url = `${process.env.URL_API_CHALUPA}/search/advanced`
  const params = {
    originLocation,
    originHaulage,
    destinationLocation,
    destinationHaulage,
    containerType,
    containerSize,
    containerQuantity,
    containerWeight,
    soc,
    commodity,
    dangerousCargo,
    searchStartDate,
    nameCompany,
    locationCompany,
    emailCompany,
    IMO,
    locations,
  }

  return getApi(url, params, token)
}

export default getAdvancedSearch
