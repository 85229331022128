import { getApi } from "../helpers/clientApi"

const getSimpleSearch = ({ originLocation, destinationLocation, searchStartDate, token, location }) => {
  const locations = JSON.stringify(location);
  const url = `${process.env.URL_API_CHALUPA}/search/simple`
  const params = {
    originLocation,
    destinationLocation,
    searchStartDate,
    locations
  }

  return getApi(url, params, token)
}

export default getSimpleSearch
